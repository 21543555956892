import axios from 'axios';
import Vue from 'vue';

import { setProcessing } from '@/store/blockui.observable';

import router from '../router';

const domain = process.env.VUE_APP_API_DOMAIN || '';
const domainAuth = process.env.VUE_APP_API_AUTH || '';
const urlAuth = `//${domainAuth}/auth/check`;

axios.interceptors.request.use(
  (config) => {
    config.baseURL = `//api.${domain}`;
    config.withCredentials = true;
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from cookie
    setProcessing(true);

    return config;
  },
  (error) => {
    // Do something with request error
    setProcessing(false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    setProcessing(false);

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    setProcessing(false);

    if (
      (error.response.status == 401 || error.response.status == 403) &&
      urlAuth !== error.response.config.url
    ) {
      router.push({
        name: 'AccessDenied',
      });
    }

    if (error.response.status >= 500) {
      router.push({
        name: 'ServerError',
      });
    }

    return Promise.reject(error);
  }
);

// eslint-disable-next-line no-shadow
Plugin.install = (Vue) => {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
