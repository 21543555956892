import axios from 'axios';

export function checkAuth() {
  const domainAuth = process.env.VUE_APP_API_AUTH || '';

  return axios.get(`//${domainAuth}/auth/check`);
}

export function logout() {
  const domainAuth = process.env.VUE_APP_API_AUTH || '';

  return axios.get(`//${domainAuth}/logout`);
}
