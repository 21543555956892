import { checkAuth } from '@/api/auth';

const routes = [
  {
    meta: {
      Auth: true,
    },
    path: '',
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/ivas',
        name: 'TablaIvas',
        component: () =>
          import(
            /* webpackChunkName: "TablaIvas" */ '../views/tablas/TablaIvas.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/divisas',
        name: 'TablaDivisas',
        component: () =>
          import(
            /* webpackChunkName: "TablaDivisas" */ '../views/tablas/TablaDivisas.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/paises',
        name: 'TablaPaises',
        component: () =>
          import(
            /* webpackChunkName: "TablaPaises" */ '../views/tablas/TablaPaises.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/formascobro',
        name: 'TablaFormasCobro',
        component: () =>
          import(
            /* webpackChunkName: "TablaFormasCobro" */ '../views/tablas/TablaFormasCobro.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/formaspago',
        name: 'TablaFormasPago',
        component: () =>
          import(
            /* webpackChunkName: "TablaFormasPago" */ '../views/tablas/TablaFormasPago.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/extras',
        name: 'TablaExtras',
        component: () =>
          import(
            /* webpackChunkName: "TablaExtras" */ '../views/tablas/TablaExtras.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/empresa',
        name: 'TablaEmpresa',
        component: () =>
          import(
            /* webpackChunkName: "TablaEmpresa" */ '../views/tablas/TablaEmpresa.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/configuracion/email',
        name: 'TablaConfiguracionEmail',
        component: () =>
          import(
            /* webpackChunkName: "TablaConfiguracionEmail" */ '../views/tablas/TablaConfiguracionEmail.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/plantillas',
        name: 'TablaPlantillas',
        component: () =>
          import(
            /* webpackChunkName: "TablaConfiguracionPlantillas" */ '../views/tablas/TablaPlantillas.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/seriesfacturacion',
        name: 'TablaSeriesFacturacion',
        component: () =>
          import(
            /* webpackChunkName: "TablaSeriesFacturacion" */ '../views/tablas/TablaSeriesFacturacion.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/tabla/caracteristicas',
        name: 'TablaCaracteristicas',
        component: () =>
          import(
            /* webpackChunkName: "TablaCaracteristicas" */ '../views/tablas/TablaCaracteristicas.vue'
          ),
      },

      {
        meta: {
          Auth: true,
        },
        path: '/proveedores',
        name: 'GestionProveedores',
        component: () =>
          import(
            /* webpackChunkName: "GestionProveedores" */ '../views/proveedores/GestionProveedores.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/proveedores/pagos',
        name: 'GestionProveedoresPagos',
        component: () =>
          import(
            /* webpackChunkName: "GestionProveedoresPagos" */ '../views/proveedores/GestionProveedoresPagos.vue'
          ),
      },

      {
        meta: {
          Auth: true,
        },
        path: '/clientes',
        name: 'GestionClientes',
        component: () =>
          import(
            /* webpackChunkName: "GestionClientes" */ '../views/clientes/GestionClientes.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/domiciliosfiscales',
        name: 'ClientesDomiciliosFiscales',
        component: () =>
          import(
            /* webpackChunkName: "ClientesDomiciliosFiscales" */ '../views/clientes/ClientesDomiciliosFiscales.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/clientes/pagos',
        name: 'GestionClientesFacturacion',
        component: () =>
          import(
            /* webpackChunkName: "GestionClientesFacturacion" */ '../views/clientes/GestionClientesFacturacion.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/fincas',
        name: 'GestionFincasAlojamientos',
        component: () =>
          import(
            /* webpackChunkName: "GestionFincasAlojamientos" */ '../views/fincas/GestionFincas.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/alojamientos',
        name: 'GestionAlojamientos',
        component: () =>
          import(
            /* webpackChunkName: "GestionAlojamientos" */ '../views/alojamientos/GestionAlojamientos.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/proveedores/:id/detalles',
        name: 'GestionProveedoresDetalles',
        component: () =>
          import(
            /* webpackChunkName: "GestionProveedoresDetalles" */ '../views/proveedores/GestionProveedoresDetalles.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/clientes/:id/detalles',
        name: 'GestionClientesDetalles',
        component: () =>
          import(
            /* webpackChunkName: "GestionClientesDetalles" */ '../views/clientes/GestionClientesDetalles.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/fincas/:id/detalles',
        name: 'GestionFincasDetalles',
        component: () =>
          import(
            /* webpackChunkName: "GestionFincasDetalles" */ '../views/fincas/GestionFincasDetalles.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/alojamientos/:id/detalles',
        name: 'GestionAlojamientosDetalles',
        component: () =>
          import(
            /* webpackChunkName: "GestionAlojamientosDetalles" */ '../views/alojamientos/GestionAlojamientosDetalles.vue'
          ),
      },
      {
        meta: {
          Auth: true,
        },
        path: '/businessinteligence/procesoeconomico',
        name: 'ProcesoEconomico',
        component: () =>
          import(
            /* webpackChunkName: "ProcesoEconomico" */ '../views/businessinteligence/ProcesoEconomico.vue'
          ),
      },
    ],
  },
  {
    path: '/404',
    meta: {
      title: 'Not found',
    },
    name: 'NotFound',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy" */
        `@/views/errors/NotFound.vue`
      ),
  },
  {
    path: '/403',
    meta: {
      title: 'Forbiden',
    },
    name: 'AccessDenied',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy" */
        `@/views/errors/DenyAccess.vue`
      ),
  },
  {
    path: '/500',
    meta: {
      title: 'Server Error',
    },
    name: 'ServerError',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy" */
        `@/views/errors/ErrorServer.vue`
      ),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: async (_to, _from, next) => {
      try {
        await checkAuth();
        next({ name: '' });
      } catch (error) {
        next();
      }
    },
  },
  { path: '*', redirect: '/404' },
];

export default routes;
