import Vue from 'vue';

const state = Vue.observable({
  processing: false,
});

export const getProcessing = () => state.processing;

export function setProcessing(payload) {
  state.processing = payload;
}
