import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css';
import '@/assets/main.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

const theme = {
  primary: '#02A8AB',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#6E3667',
  info: '#2196F3',
  success: '#02A8AB',
  warning: '#FFC107',
  lightblue: '#14c6FF',
  yellow: '#FFCF00',
  pink: '#FF1976',
  orange: '#FF8657',
  magenta: '#C33AFC',
  darkblue: '#1E2D56',
  gray: '#909090',
  neutralgray: '#9BA6C1',
  green: '#02A8AB',
  red: '#ff5252',
  darkblueshade: '#308DC2',
  lightgray: '#BDBDBD',
  lightpink: '#FFCFE3',
  white: '#FFFFFF',
};

const vuetify = new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});

export default vuetify;
