import Vue from 'vue';
import VueRouter from 'vue-router';

import { checkAuth } from '@/api/auth';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.Auth && to.name !== 'Login') {
    try {
      await checkAuth();
      next();
      return;
    } catch (error) {
      next({ path: '/login' });
      return;
    }
  }
  next();
});

export default router;
