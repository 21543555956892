<template>
  <div>
    <v-dialog v-model="processing" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('comp_blockui_actu') }}...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getProcessing as processing } from '../store/blockui.observable';
export default {
  name: 'block-ui',
  computed: {
    processing,
  },
};
</script>

<style scoped></style>
