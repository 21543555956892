<template>
  <div id="app">
    <v-app>
      <blockui></blockui>
      <snacknotify />
      <router-view />
    </v-app>
  </div>
</template>

<script>
import snacknotify from '@fitcloud/vuetify-snacknotify';

import blockui from '@/components/BlockUI';

export default {
  name: 'App',
  components: {
    blockui,
    snacknotify,
  },
};
</script>
