import './plugins/axios';

import { localize, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import VueMobileDetection from 'vue-mobile-detection';

import App from './App.vue';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';

import(`vee-validate/dist/locale/es.json`).then((locale) => {
  localize('es', locale);
});

// eslint-disable-next-line simple-import-sort/imports
import { mutationsFilemanager } from '@fitcloud/filemanager';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

const mapsAPIKey = process.env.VUE_APP_MAPS_API || '';

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: mapsAPIKey, // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
});
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false;

Vue.use(VueMobileDetection);

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

const domain = process.env.VUE_APP_API_DOMAIN_FILES || '';

mutationsFilemanager.setUrlConfig(`//files.${domain}`);
